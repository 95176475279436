@use "../../../../colors" as COLORS;

.root {
  max-width: 540px;
  font-size: 20px;
  font-weight: 300;
  line-height: 32px;
  hyphens: none;
}

.caption {
  font-size: 24px;
  margin: 24px 0;
  font-weight: 400;
}

@media only screen and (max-width: 1270px) {
  .root {
    max-width: 100%;
  }
}