@use "../../colors" as COLORS;

$DEFAULT_TEXT_SHADOW: 4px 4px 16px rgba(0, 0, 0, 0.5);

header {
  padding: 0 8px;
}

.landingPageImg {
  border: 2px solid COLORS.$CLOUD_BURST_20;
  border-radius: 4px;
  background: COLORS.$WHITE;
  padding: 24px;
}

.content {
  font-family: "Source Sans Pro", sans-serif;
  background-color: #fdfeff;
  color: COLORS.$WHITE;
  hyphens: auto;
  overflow: hidden;
  a {
    color: #243e54;
    hyphens: none;
  }
  strong {
    font-weight: 600;
  }
}

.heroImg {
  max-width: 1200px;
  width: 95%;
  border-radius: 16px;
  box-shadow: 0 0 4px rgba(0, 0, 0, 50%);
}

.heroWrapper {
  background-image: url("../../img_9/Hero_Background_Blur.jpg");
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: top;
  background-size: cover;
  width: 100%;
  padding-top: 104px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.title {
  font-size: 104px;
  display: flex;
  gap: 16px;
  justify-content: center;
}

.logoWrapper {
  display: inline-block;
  max-width: 440px;
  width: 95%;
}

.version {
  display: flex;
  line-height: 96px;
  color: #1b6694;
  background: COLORS.$WHITE;
  border-radius: 16px;
  padding: 4px 24px 8px 24px;
  align-self: center;
}

.subtitle {
  text-align: center;
  max-width: 1000px;
  color: COLORS.$WHITE;
  font-size: 36px;
  font-weight: 300;
  margin: 48px 0;
  line-height: 48px;
  text-shadow: $DEFAULT_TEXT_SHADOW;
  hyphens: none;
}

.heading {
  font-size: 20px;
  font-weight: 600;
}

.backdrop {
  background-color: #00000033;
  backdrop-filter: blur(40px);
  mask: linear-gradient(transparent, #999);
  width: 100%;
  height: 100px;
}

.backdropReverse {
  @extend .backdrop;
  mask: linear-gradient(#999, transparent);
}

.sectionContent {
  display: flex;
  flex-grow: 1;
  justify-content: center;
  flex-direction: column;
  max-width: 1200px;
  margin: 0 24px;
  align-items: center;
}

.caption {
  text-align: center;
  line-height: 56px;
  font-size: 40px;
  font-weight: 400;
  margin: 32px 0;
}

.subcaption {
  font-size: 28px;
  line-height: 40px;
  margin: 64px 0;
}

.verticalSpace {
  height: 100px;
}

.lightWrapper {
  color: COLORS.$CLOUD_BURST_100;
  display: flex;
  flex-direction: column;
  justify-self: center;
  justify-content: center;
  text-align: justify;
  align-items: center;
}

.darkWrapper {
  @extend .lightWrapper;
  background: linear-gradient(120deg, COLORS.$JANITZA_BLUE_05 0%, COLORS.$JANITZA_BLUE_10 100%);
}

.imageWrapper {
  background: url("../../img/hero.png") no-repeat top;
  background-size: cover;
  min-height: 1000px;
}

.columns {
  margin-top: 48px;
  display: flex;
  justify-content: space-between;
  gap: 40px;
  :nth-child(1) {
    background-position: 0 10%;
  }
  :nth-child(2) {
    background-position: 20% 10%;
  }
  :nth-child(3) {
    background-position: 40% 10%;
  }
  > div {
    width: 30%;
    &:last-child {
      margin: 0;
    }
  }
}

.column {
  padding: 48px 24px;
  backdrop-filter: blur(8px);
  color: COLORS.$WHITE;
  border-radius: 16px;
  font-weight: 300;
  background: rgba(5, 15, 30, 33%);
  box-shadow: 4px 4px 16px rgb(0 0 0 / 25%);
  font-size: 20px;
  line-height: 28px;
  border: 1px solid #fff3;
  .heading {
    font-size: 24px;
    margin-bottom: 16px;
  }
}

.imgFlexWrapper {
  flex-shrink: 1;
  flex-grow: 1;
}

.flexImage {
  max-width: 100%;
  border-radius: 16px;
  box-shadow: 0 0 8px #ccc;
}


.lightWrapperMore {
  @extend .lightWrapper;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: top;
  background-size: cover;  background-image: url("../../img_9/Hero_Background_Blur.jpg");
}

.flexWrapper {
  justify-content: flex-start;
  display: flex;
  gap: 100px 120px;
  flex-wrap: wrap;
}

.textImgRow{
  display: flex;
  align-items:center;
  gap: 160px 120px;

  .flexImage {
    max-width: 45%;
    border-radius: 8px;
    box-shadow: 0 0 4px rgba(0, 0, 0, 20%);
  }

}


@media only screen and (max-width: 1200px) {
  .textImgRow{  
    flex-wrap: wrap;
    gap: 48px 120px;
    .flexImage {
      max-width: 100%;
      margin-left: auto;
      margin-right: auto;
    }
  }
  .textImgRow:nth-child(2){
    flex-direction: column-reverse;
  }
}

@media only screen and (max-width: 900px) {
  .columns {
    flex-direction: column;
    > div {
      box-sizing: border-box;
      width: 100%;
      margin-bottom: 32px;
    }
  }
}
